const mainEndpoint = 'https://app2.oscar-black.com/api'

export default {

  // Endpoints
  loginEndpoint: `${mainEndpoint}/login_check`,
  registerEndpoint: `${mainEndpoint}/register`,
  refreshEndpoint: `${mainEndpoint}/token/refresh`,
  logoutEndpoint: `${mainEndpoint}/jwt/logout`,
  forgottenPasswordEndpoint: `${mainEndpoint}/user/me/reset_password`,

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',
}
