export default [
  {
    path: '/paiement',
    name: 'paiement',
    component: () => import('@/views/paiement/Paiement.vue'),
    meta: {
      pageTitle: 'Moyen de paiement',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'Moyen de paiement',
          active: true,
        },
      ],
      resource: 'User',
    },
  },
]
