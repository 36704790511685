export default [
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/dashboard/ecommerce/Ecommerce.vue'),
    meta: {
      pageTitle: 'Dashboard',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'Dashboard',
          active: true,
        },
      ],
      resource: 'User',
    },
  },
  {
    path: '/indicateurs',
    name: 'indicateurs',
    component: () => import('@/views/dashboard/analytics/Analytics.vue'),
    meta: {
      pageTitle: 'Indicateurs entreprise',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'Indicateurs',
          active: true,
        },
      ],
      resource: 'Admin',
    },
  },
]
