export default [
  {
    path: '/parrainage',
    name: 'parrainage',
    component: () => import('@/views/parrainage/Parrainage.vue'),
    meta: {
      pageTitle: 'Parrainage',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'Parrainage',
          active: true,
        },
      ],
      resource: 'User',
    },
  },
]
