export default [
  {
    path: '/admin',
    name: 'admin',
    component: () => import('@/views/admin/users-list/UsersList.vue'),
    meta: {
      pageTitle: 'Clients',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'Clients',
          active: true,
        },
      ],
      resource: 'Admin',
    },
  },
  {
    path: '/admin/view/:id',
    name: 'admin-view',
    component: () => import('@/views/admin/users-view/UsersView.vue'),
    meta: {
      pageTitle: 'Détail client',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'Clients',
          to: '/admin',
        },
        {
          text: 'Détail',
          active: true,
        },
      ],
      resource: 'Admin',
    },
  },
  {
    path: '/admin/edit/:id',
    name: 'admin-edit',
    component: () => import('@/views/admin/users-edit/UsersEdit.vue'),
    meta: {
      pageTitle: 'Modification client',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'Clients',
          to: '/admin',
        },
        {
          text: 'Modification',
          active: true,
        },
      ],
      resource: 'Admin',
    },
  },
]
