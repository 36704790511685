export default [
  {
    path: '/profile',
    name: 'profile',
    component: () => import('@/views/profile/users-view/UsersView.vue'),
    meta: {
      pageTitle: 'Mon profil',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'Profil',
          active: true,
        },
      ],
      resource: 'User',
    },
  },
  {
    path: '/profile/edit',
    name: 'profile-edit',
    component: () => import('@/views/profile/users-edit/UsersEdit.vue'),
    meta: {
      pageTitle: 'Modification profil',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'Profil',
          to: '/profile',
        },
        {
          text: 'Modification',
          active: true,
        },
      ],
      resource: 'User',
    },
  },
]
