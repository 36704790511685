import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
// import apps from './routes/apps'
import tache from './routes/tache'
import design from './routes/design'
import audience from './routes/audience'
// import demande from './routes/demande'
import drive from './routes/drive'
import parrainage from './routes/parrainage'
import fichier from './routes/fichier'
import message from './routes/message'
import messageadmin from './routes/messageadmin'
import facture from './routes/facture'
import meet from './routes/meet'
import paiement from './routes/paiement'
import gestion from './routes/gestion'
import logs from './routes/logs'
import stats from './routes/stats'
import admin from './routes/admin'
import dashboard from './routes/dashboard'
// import uiElements from './routes/ui-elements/index'
import pages from './routes/pages'
import profile from './routes/profile'
// import chartsMaps from './routes/charts-maps'
// import formsTable from './routes/forms-tables'
// import others from './routes/others'
import axios from '@axios'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'dashboard' } },
    // ...apps,
    ...tache,
    ...design,
    ...audience,
    // ...demande,
    ...drive,
    ...parrainage,
    ...fichier,
    // ...gestion,
    ...message,
    ...messageadmin,
    ...facture,
    ...meet,
    ...paiement,
    ...logs,
    ...stats,
    ...admin,
    ...dashboard,
    ...pages,
    ...profile,
    // ...chartsMaps,
    // ...formsTable,
    // ...uiElements,
    // ...others,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  let isLoggedIn = isUserLoggedIn()

  console.log(to)

  axios.get('https://app2.oscar-black.com/api/me')
  .then(response => {
    if(response.data.subscription === 0) {
      return next({ name: 'pages-pricing'})
    }
  })
  .catch(() => {
    isLoggedIn = false
    if(to.meta.redirectIfLoggedIn) {
      return next()
    }
    else {
      return next({ name: 'auth-login' })
    }
  })

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) {
      return next({ name: 'auth-login' })
    }

    // If logged in => not authorized
    return next({ name: 'misc-not-authorized' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  }

  return next()
})

export default router
