export default [
  {
    path: '/logs',
    name: 'logs',
    component: () => import('@/views/logs/Logs.vue'),
    meta: {
      pageTitle: 'Logs',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'Logs',
          active: true,
        },
      ],
      resource: 'Admin',
    },
  },
]
