export default [
  {
    path: '/drive',
    name: 'drive',
    component: () => import('@/views/drive/Directory.vue'),
    meta: {
      pageTitle: 'Drive',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'Drive',
          active: true,
        },
      ],
      resource: 'User',
    },
  },
]
