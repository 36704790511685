import mock from '@/@fake-db/mock'
/* eslint-disable global-require */
const data = {
  pricing: {
    bothPlan: {
      title: 'Facebook Ads + Google Ads',
      img: require('@/assets/images/illustration/Pot3.svg'),
      subtitle: 'Une puissance combinée',
      monthlyPrice: 690,
      yearlyPlan: {
        perMonth: 490,
        totalAnual: 5880,
      },
      planBenefits: [
        'Contenu des offres Facebook Ads + Google Ads',
        'Touchez l\'ensemble de vos prospects potentiels',
        'Ciblez les utilisateurs actifs et passifs',
        'Renforcez votre présence digitale',
      ],
      popular: true,
    },
    standardPlan: {
      title: 'Facebook Ads',
      img: require('@/assets/images/illustration/Pot1.svg'),
      subtitle: 'Paramétrer votre espace publicitaire, lancer vos premières publicités à moindre frais.',
      monthlyPrice: 390,
      yearlyPlan: {
        perMonth: 290,
        totalAnual: 3480,
      },
      planBenefits: [
        'Publicités illimitées',
        'Veille Hebdomadaire',
        'Tous formats publicitaires',
        'Tests d\'audiences : Targeting - Retargeting - Lookalike',
        'Support sur rendez-vous 5J/7',
      ],
      popular: false,
    },
    enterprisePlan: {
      title: 'Google Ads',
      img: require('@/assets/images/illustration/Pot1.svg'),
      subtitle: 'Gagnez de nouveaux clients grâce à la puissance de Google Ads.',
      monthlyPrice: 390,
      yearlyPlan: {
        perMonth: 290,
        totalAnual: 3480,
      },
      planBenefits: [
        'Publicités illimitées',
        'Veille Quotidienne',
        'Tous formats de campagnes',
        'Accompagnement total : Paramétrage - Lancement - Optimisation',
        'Support sur rendez-vous 5J/7',
      ],
      popular: false,
    },
    qandA: [
      {
        question: 'Je souhaite soucrire à l\'offre Facebook Ads ou Google Ads, comment se passe le début ?',
        ans:
          'A l\'issue de l\'inscription, nous vous demandons de nous donner accès à votre compte publicitaire / compte Google Ads et de mettre en place certaines choses (le pixel pour Facebook Ads par exemple). Un tutoriel vous sera fourni. Si vous n\'y arrivez pas, pas de panique. Dans tous les cas, notre équipe opérationnelle prend contact avec vous par téléphone pour que vos campagnes puissent se lancer dans les meilleures conditions.',
      },
      {
        question: 'Est-ce que mon abonnement se renouvelle automatiquement ?',
        ans:
          'Votre abonnement annuel ou mensuel se renouvellera automatiquement en effet. Vous pouvez à tout moment nous demander d\'arrêter la prestation jusqu\'à 5 jours avant la date anniversaire de votre abonnement.',
      },
      {
        question: 'Quel budget sera alloué à mes publicités Facebook, Instagram et Google ?',
        ans:
          'Nous débutons avec un budget quotidien de 10€ /jour (conseillé). Dans un premier temps, le but est d\'identifier le ou les audiences qui vous apporterons les meilleures performances. Une fois les cibles réactives identifiées, nous vous proposerons d\'augmenter ce budget 🚀.',
      },
      {
        question: 'Quels résultats allez-vous m\'apporter ?',
        ans:
          'Le ROI (retour sur investissement) dépendra de votre domaine d\'activité (ecommerce, app, B2B, B2C) et du budget alloué à la publicité. Dans un premier temps, nous nous attacherons à optimiser votre coût par clic (CPC). Pour un budget de 10€/jour, vous pourrez espérer de 16000 à 24000 impressions sur votre publicité et de 1000 à 3 000 visiteurs sur votre site internet chaque mois.',
      },
      {
        question: 'Qui gère mes campagnes publicitaires ?',
        ans:
          'Un chargé de campagnes vous accompagnera dans la gestion de vos publicités en ligne. Celui-ci est disponible 5 / 7 jour, par téléphone (en offre Premium) ou par mail (en offre Standard). Il aura pour principale mission de vérifier et améliorer quotidiennement vos campagnes (budget, cible, visuels, format,etc). Celui-ci vous fournira également un rapport détaillé chaque semaine.',
      },
      {
        question: 'Comment voir les résultats de mes campagnes ?',
        ans:
          'Chaque semaine, votre chargé de campagnes vous transmettra un rapport détaillé. Les chiffres qui le constituent sont également accessibles 24h/24 et 7J/7 depuis votre espace personnel dans l\'onglet "Dashboard".',
      },
      {
        question: 'Qui crée les visuels de mes publicités en ligne ?',
        ans:
          'L\'idéal : vous nous fournissez les informations et les images, nous créons les visuels et diffusons les publicités.',
      },
      {
        question: 'Combien de publicités allez-vous créer pour moi ?',
        ans:
          'Dans un premier temps, nous créerons une publicité pilote afin de tester la réactivité de centaines de cibles marketing différentes en faisant de l\'A/B testing. Une fois que les audiences le plus pertinentes seront identifiées, nous mettrons en place de nouveaux visuels selons vos envies. Il n\'y a donc pas de limite.',
      },
      {
        question: 'J\'ai d\'autres questions, comment entrer en contact avec vous ?',
        ans:
          'Une seule adresse, on vous répond en moins de 1h : contact@oscar-black.com',
      },
    ],
  },
}
/* eslint-disable global-require */
mock.onGet('/pricing/data').reply(() => [200, data.pricing])
