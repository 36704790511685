export default [
  {
    path: '/stats/:id',
    name: 'stats',
    component: () => import('@/views/stats/Stats.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
]
