export default [
  {
    path: '/audience',
    name: 'audience',
    component: () => import('@/views/audience/Audience.vue'),
    meta: {
      pageTitle: 'Audience',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'Audience',
          active: true,
        },
      ],
      resource: 'User',
    },
  },
]
