export default [
  {
    path: '/facture',
    name: 'facture',
    component: () => import('@/views/facture/Facture.vue'),
    meta: {
      pageTitle: 'Factures',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'Factures',
          active: true,
        },
      ],
      resource: 'User',
    },
  },
]
