export default [
  {
    path: '/drive/:id',
    name: 'fichier',
    component: () => import('@/views/drive/Drive.vue'),
    meta: {
      pageTitle: 'Contenu du dossier',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'Drive',
          to: '/drive',
        },
        {
          text: 'Contenu du dossier',
          active: true,
        },
      ],
      resource: 'User',
    },
  },
]
