export default [
  {
    path: '/tache',
    name: 'tache',
    component: () => import('@/views/tache/Tache.vue'),
    meta: {
      pageTitle: 'Tâches',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'Taches',
          active: true,
        },
      ],
      resource: 'Admin',
    },
  },
]
